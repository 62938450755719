.Model {
	/* background-color: #ffb504; */
	width: 100%;
	/* height: 446px; */
	/* min-height: 324px; */
	height: 100%;
	padding: 1rem 2rem;
}

.Card {
	width: 100%;
	height: 100%;
	/* padding: 1rem 2rem; */
	display: flex;
	flex-direction: column;
}

.Contact {
	width: 100%;
	/* height: 100%; */
	height: auto;
	display: flex;
	flex-direction: column;
	/* margin-top: 3rem; */
	margin-bottom: 1rem;
}

.FormGroup {
	display: flex;
	flex-direction: row;
	color: inherit;
	font-weight: bold;
	font-size: 18px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	/* padding: 0.5rem; */
	padding: 0.25rem;
}

.FormGroup span:first-child {
	width: 90%;
}

.FormGroup2 {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: inherit;
	font-weight: bold;
	font-size: 18px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	/* padding: 0.5rem; */
	padding: 0.25rem;
}
.FGCp {
	font-size: 16px;
	font-weight: bold;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	margin-bottom: 0.5rem;
}
.FormGroup2 .Svg,
.FormGroup2 .Svg {
	display: flex;
	justify-content: center;
	margin: auto 1rem auto 0;
}

.Button {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	/* margin-top: 2rem; */
}

.Button a {
	text-decoration: none;
	color: inherit;
	display: flex;
	flex: 1;
}
.Button button:hover,
.Button a:hover {
	background-color: unset;
}
.Button button:focus,
.Button button:active {
	background-color: unset;
	box-shadow: none !important;
}

.Button button {
	padding: 1rem;
	text-align: left;
	/* border: 1px solid #000000; */
	font-weight: bold;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	display: flex;
	flex-direction: row;
	border-radius: 5px;
	background-color: unset;
	cursor: pointer;
	align-items: center;
	color: inherit;
}

.Button button span {
	width: 90%;
}

.Button button:not(:first-child),
.Button a:not(:first-child) {
	margin-top: 1rem;
}

.Type {
	margin-bottom: 1rem;
}

@media only screen and (min-width: 2559px) {
	.Model {
		padding: 2rem;
	}
}

@media only screen and (max-width: 2060px) {
	.Type,
	.Contact {
		margin-bottom: 0.5rem;
	}
	.Button button:not(:first-child),
	.Button a:not(:first-child) {
		margin-top: 0.5rem;
	}
}

@media only screen and (max-width: 1920px) and (min-width: 900px) {
	.Type,
	.Contact {
		margin-bottom: 0.5rem;
	}
	.Button button:not(:first-child),
	.Button a:not(:first-child) {
		margin-top: 0.5rem;
	}
}
@media only screen and (max-width: 1680px) and (min-height: 900px) {
	.Type,
	.Contact {
		margin-bottom: 0.5rem;
	}
	.Button button:not(:first-child),
	.Button a:not(:first-child) {
		margin-top: 0.5rem;
	}
}
@media only screen and (max-width: 1440px) and (min-height: 680px) {
	.Type,
	.Contact {
		margin-bottom: 0.5rem;
	}
	.Button button:not(:first-child),
	.Button a:not(:first-child) {
		margin-top: 0.5rem;
	}
}

@media only screen and (max-width: 1680px) {
	.Model {
		padding: 1rem;
	}
	.Button button {
		padding: 0.5rem;
	}
	.container > .Type,
	.container > .Contact {
		margin-bottom: 0.15rem;
	}
}

@media only screen and (max-width: 1440px) {
	.Model {
		padding: 0.75rem;
	}
	.Button button {
		padding: 0.25rem;
	}
}

@media only screen and (max-width: 1366px) {
	.Model {
		padding: 0.5rem 0.75rem;
	}
	.container > .Type,
	.container > .Contact {
		margin-bottom: 0rem;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1365px) and (min-height: 576px) and (max-height: 720px) {
	.Model {
		padding: 0.75rem;
	}
	.FormGroup,
	.FormGroup2 {
		padding: 0.15rem;
	}
	.Button button {
		padding: 0.35rem;
	}
	.Card span {
		font-weight: 500;
	}
}
@media only screen and (min-width: 1024px) and (max-width: 1365px) and (max-height: 576px) {
	.Model {
		padding: 1rem 1.5rem;
		min-height: auto;
		overflow-y: scroll;
	}
	.FormGroup,
	.FormGroup2 {
		padding: 0.15rem;
	}
	.Button button {
		padding: 0.35rem;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1224px) {
	.Model {
		height: 100%;
		padding: unset;
	}
	.FormGroup,
	.FormGroup2 {
		font: bold 12px 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		line-height: 12px;
	}
	.Button button span {
		font-size: 12px;
	}
	.Button {
		margin-top: 0rem;
	}
	.container > .Contact {
		margin-top: 0rem;
	}
	.FGCp {
		margin-bottom: 0.25rem;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1224px) and (max-height: 678px) {
	.Model {
		min-height: fit-content;
		overflow: auto;
	}
	.container > .Type,
	.container > .Contact {
		margin-bottom: 0;
	}
	.FormGroup,
	.FormGroup2 {
		font: bold 10px 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	}
	.container > .Button,
	.container > .Contact {
		margin-top: 0.15rem;
	}
	.Button button {
		padding: 0 0.15rem;
	}
	.Button button:not(:first-child),
	.Button a:not(:first-child) {
		margin-top: 0.25rem;
	}
	.Button button .Svg svg {
		width: 0.5em !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1224px) and (max-height: 576px) {
	.FormGroup,
	.FormGroup2 {
		line-height: 8px;
	}
}

@media only screen and (max-width: 760px) {
	.Model {
		padding: 1rem;
	}
	.FormGroup {
		font: bold 14px 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	}
	.FormGroup2 {
		font: bold 14px 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	}
	.Button {
		height: auto;
	}
	.Button button {
		padding: 0.5rem 0.75rem;
		font: bold 14px 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		width: 100%;
		height: 100%;
		display: flex;
		flex: 1;
	}
}
