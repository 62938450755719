.__containerLogin {
	width: 100%;
	padding: 0 2rem;
	display: flex;
	flex-direction: row;
}

.__boxLogin {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
}

.__boxForm {
	width: 25%;
	height: 70%;
	margin: auto;
	display: flex;
	flex-direction: column;
}

.__boxForm h1 {
	font-size: 2rem;
}

.__wrapInput {
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
}

/* .__wrapInput span {
	font-size: 14px;
	color: #b4b0b0;
	word-spacing: 2px;
	margin-left: 1rem;
} */

.__boxInput {
	border-radius: 5px;
	padding: 0.5rem 0.7rem;
	border: 2px solid #b4b0b0;
	height: 50px;
}

.__errText {
	color: red;
	font-size: 1rem;
	word-spacing: 2px;
	margin-left: 1rem;
}

.__boxInput:focus {
	outline: unset;
}

.__showButton {
	width: 100%;
	height: 53px;
	border: unset;
	border-radius: 5px;
	background-color: #d6d6d6;
	margin-top: 2rem;
	color: white;
	font-weight: bold;
	font-size: 1rem;
}

.__changed {
	background-color: #2e4d80;
}

.__btmButton {
	width: 100%;
	height: 53px;
	border: unset;
	border-radius: 5px;
	background-color: #2e4d80;
	margin-top: 1rem;
	color: white;
	font-weight: bold;
	font-size: 1rem;
}

.__textCookie {
	margin-top: 0.5rem;
	font-size: 16px;
	color: #b4b0b0;
}

.__orText {
	font-size: 16px;
	text-align: center;
}

.loaderContainer {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-width: 2560px) {
}

@media only screen and (min-width: 1649px) and (max-width: 1660px) {
}
@media only screen and (min-width: 1366px) and (max-width: 1648px) {
	.__boxForm {
		width: 35%;
	}
	.__boxInput {
		height: 45px;
	}

	.__textCookie {
		font-size: 14px;
	}

	.__wrapInput span {
		font-size: 12px;
	}

	.__orText {
		font-size: 14px;
	}
}

@media only screen and (min-width: 1366px) and (max-width: 1648px) {
	
}
