.MenuList {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: auto;
    margin: auto;

	justify-content:flex-start ;
	flex-wrap: wrap;
}

.MenuList > button.btnM {
	padding: .5rem 0;
    width: auto;
	margin: .5rem .5rem .5rem 0;
	height: fit-content;
	border: none;
	line-height: 1.5;
}
.MenuList > a {
    width: auto;
	margin: .5rem .5rem .5rem 0;
	height: fit-content;
	border: none;
}
.MenuList > a.btnM > button {
	padding: .5rem 0;
    width: auto;
	margin: 0;
	line-height: 1.5;
	border: none;
}

.MenuList span {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
	margin: 0 1rem;
}