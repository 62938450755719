.Board6 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Top6 {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.Top6 h5 {
  margin-bottom: unset;
}

.Top6 .Svg {
  width: 10%;
  display: flex;
  justify-content: center;
}

.__slider {
  background-color: antiquewhite;
  width: 100%;
  height: 100%;
}

.CardImgs {
  width: 100% !important;
  height: 100%;
}

.aCardImgs {
  width: 100% !important;
  height: 100%;
  display: block;
  position: relative;
  z-index: 1000;
}

.__slider {
	background-color: antiquewhite;
	width: 100%;
	height: 100%;
}

.CardImgs {
	width: 100% !important;
	height: 100%;
}

.aCardImgs {
	width: 100% !important;
	height: 100%;
	display: block;
    position: relative;
    z-index: 1000;
}

.Svg svg {
  margin: auto;
}

.Top6 h1 {
  font-size: 32px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  width: 90%;
}
.Btm6 {
  width: 100%;
  height: 80%;
}

.Btm6 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dummyImage {
  object-fit: contain !important;
}

.dummyImage {
	object-fit: contain !important;
}

@media only screen and (max-width: 1440px) {
  .Top6 {
    padding: 0.75rem 0;
  }
  .Top6 h5 {
    font-size: 18px;
  }

}
