/* LOADER */
.loaderContainer {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ParentContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.ParentContainer > div:first-child {
	display: flex;
	flex-direction: column;
}
.ParentContainer > div:last-child {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.ParentContainer1 {
	width: 100%;
}
.ParentContainer1 > div:first-child {
	width: 100%;
}

.Up {
	position: relative;
	display: flex;
	flex-direction: row;
}

.Bottom {
	position: relative;
	display: flex;
	flex-direction: row;
	/* padding: 0 1rem 0 0; */
}

.Section1 {
	position: relative;
	width: 35%;
	height: auto;
}

.Section2 {
	position: relative;
	height: auto;
	padding: 0.75rem;
}

.Section3 {
	position: relative;
	height: 100%;
	/* margin-top: 1rem; */
}

.Section4 {
	position: relative;
	width: 100%;
	margin-top: 1.25rem;
	height: 100%;
}


.Section5 {
	position: relative;
	background-color: #fafafa;
	width: 100%;
	padding: 1rem 0;
}

.Section6 {
	position: relative;
	background-color: white;
	width: 100%;
	height: 100%;
	padding: 0rem 1rem;
	/* margin-top: 1rem; */
}
.Grouped {
	width: 65%;
	display: flex;
	flex-direction: column;
}

@media only screen and (max-width: 1440px) {
	.Section2 {
		padding: 0rem 0.5rem 0.25rem 0.5rem;
	}
	.Section4 {
		margin-top: 1rem;
	}
	.Section6 {
		padding: 0rem 0.5rem;
	}
}


@media only screen and (max-width: 1025px) {
	.Section4 {
		margin-top: 0.5rem;
	}
}



