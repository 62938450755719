.mobileHeader {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* background: #232a3a; */
}

.contentHeader {
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  padding: 0.75rem 1rem;
}
.contentTop {
  display: flex;
  width: 90% !important;
}
.contentHeader:first-child {
  justify-content: space-between;
}

.contentHeader:last-child {
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 30%;
  height: 100%;
  padding: 0 1rem 0 0;
  margin-right: 1rem;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.organization {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.organization span:first-child {
  font-weight: bold;
  font-size: 14px;
}
.organization span:last-child {
  font-weight: 100;
  font-size: 12px;
}
.weather {
  width: 100%;
  height: 100%;
  position: relative;
}
.lang select {
  background: transparent;
  outline: none;
  border: none;
}
.lang select option {
  /* margin: 40px; */
  background: rgba(255, 255, 255, 1);
  color: #000000;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

/* @media only screen and (min-width: 769px) and (max-width: 867px) { */
@media only screen and (min-width: 769px) {
  .contentHeader {
    height: 78px;
  }
}
