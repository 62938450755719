body {
	margin: 0;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fafafa !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
	width: 2px;
	height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(174, 170, 165, 0.5);
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(146, 146, 146, 0.5);
}

.Dashboard {
	width: 100%;
	height: 100%;
}

.Footer {
	width: 100%;
	/* height: 78px; */
	height: auto;
	background-color: #fafafa;
	padding: 0.5rem 0 1rem 0;
}

.Div {
	height: 100%;
	display: flex;
	justify-content: center;
}

.Div span {
	color: #6375a2;
	font: bold 18px 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	text-align: center;
	justify-content: center;
	display: flex;
	margin: auto;
}

/*page style setting*/

.Container {
	width: 100%;
	background-color: #fafafa;
	display: flex;
	flex-direction: row;

	/* new */
	position: relative;
	/* overflow: hidden; */
}
.Container.container-basic {
	width: 80%;
	margin: 0 auto;
}

.ContainerTab {
	width: 100%;
	padding: 0 2rem;
	background-color: #fafafa;
	display: flex;
	flex-direction: column;
}

.Menues {
	width: 100%;
	height: 100%;
	padding: 0 1rem;
}

.MenuesChild {
	height: 100%;
	display: grid;
	grid-template-rows: repeat(2, 1fr);
	grid-template-columns: repeat(5, auto);
	grid-column-gap: 12px;
}
.MenuesChild-basic {
	grid-template-rows: repeat(2, 1fr);
	grid-template-columns: repeat(3, auto);
	grid-column-gap: 12px;
}
.MenuesChild-standard {
	grid-template-rows: repeat(2, 1fr);
	grid-template-columns: repeat(4, auto);
	grid-column-gap: 12px;
}

.Cards {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 0.5rem;
}

.Cards a {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: inherit;
	text-decoration: none;
}

.Cards a:hover {
	color: inherit;
}

.Cards svg,
.Cards img {
	width: calc(((80 * 65vh) / 100) / 5);
	height: 80%;
	margin: 0.25rem auto;
	color: #333333;
}

.Cards span {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	/* font-size: 16px; */
	font-weight: bold;
	text-align: center;
	/* margin-top: 1rem; */
}

/*SLIDER CUSTOM*/

#slider-customize-img .slick-slider {
	height: 100%;
}

#slider-customize-img .slick-list {
	height: 100%;
}

#slider-customize-img .slick-track {
	height: 100%;
}

#slider-customize-img .slick-slide > div {
	height: 100%;
}

#slider-customize-img .slick-prev {
	left: 2px;
}

#slider-customize-img .slick-next {
	right: 2px;
}

#slider-customize-img .slick-prev,
#slider-customize-img .slick-next {
	z-index: 1;
	top: 50%;
	background-color: #d6d6d61c;
	width: 70px;
	height: 60px;
	padding: 0.2rem;
	box-shadow: rgb(0 0 0 / 15%) 0px 15px 25px, rgb(0 0 0 / 5%) 0px 5px 10px;
}

#slider-customize-img .slick-prev:before,
#slider-customize-img .slick-next:before {
	font-size: 60px;
	color: #d6d6d6;
}

#slider-customize-tab .slick-slider {
	height: 100%;
}

#slider-customize-tab .slick-list {
	height: 100%;
}

#slider-customize-tab .slick-track {
	height: 100%;
}

#slider-customize-tab .slick-slide > div {
	height: 100%;
}

#slider-customize-tab .slick-prev {
	left: 2px;
}

#slider-customize-tab .slick-next {
	right: 2px;
}

#slider-customize-tab .slick-prev,
#slider-customize-tab .slick-next {
	z-index: 1;
	top: 50%;
	background-color: #d6d6d61c;
	width: 40px;
	height: 30px;
	padding: 0.2rem;
	box-shadow: rgb(0 0 0 / 15%) 0px 15px 25px, rgb(0 0 0 / 5%) 0px 5px 10px;
}

#slider-customize-tab .slick-prev:before,
#slider-customize-tab .slick-next:before {
	font-size: 30px;
	color: #d6d6d6;
}

.Footer a {
	text-decoration: none;
	color: inherit;

	position: relative;
	display: flex;
	align-items: center;
	align-content: center;
}

.Footer a span {
	margin-right: 0.5rem;
}

.Footer a img {
	height: 26px;
	margin-top: 0.1rem;
}

/**/

@media only screen and (min-width: 2560px) and (min-height: 1440px) {
	.Cards svg,
	.Cards img {
		height: 80%;
		width: calc(((80 * 65vh) / 100) / 5);
	}
}

@media only screen and (min-width: 1920px) and (min-height: 1080px) {
	.Cards svg,
	.Cards img {
		height: 60%;
	}
	.MenuesChild-standard {
		grid-auto-rows: auto;
	}
	.MenuesChild {
		grid-auto-rows: auto;
	}
}

@media only screen and (min-width: 1680px) and (max-width: 1920px) and (min-height: 991px) {
	.Cards svg,
	.Cards img {
		height: 50%;
		width: calc(((50 * 50vh) / 100) / 5);
	}
}
@media only screen and (min-width: 1680px) and (max-width: 1920px) and (min-height: 768px) {
	.Cards svg,
	.Cards img {
		height: 50%;
		width: calc(((50 * 50vh) / 100) / 5);
	}
}
@media only screen and (min-width: 1680px) and (max-width: 1920px) and (min-height: 380px) {
	.Cards svg,
	.Cards img {
		height: 50%;
		width: calc(((50 * 50vh) / 100) / 5);
	}
}
@media only screen and (max-width: 1680px) and (max-height: 480px) {
	.Cards svg,
	.Cards img {
		height: 40%;
		width: calc(((50 * 50vh) / 100) / 5);
		margin: 0.1rem auto;
	}
	.MenuesChild {
		grid-column-gap: 0px;
	}
	.MenuesChild-standard {
		grid-auto-rows: auto;
	}
	.MenuesChild {
		grid-auto-rows: auto;
	}
}
@media only screen and (min-width: 1441px) and (max-width: 1680px) and (min-height: 769px) and (max-height: 990px) {
	.Cards svg,
	.Cards img {
		height: 50%;
		width: calc(((60 * 60vh) / 100) / 5);
	}
}
@media only screen and (min-width: 1441px) and (max-width: 1680px) and (min-height: 480px) and (max-height: 768px) {
	.Cards svg,
	.Cards img {
		height: 40%;
		width: calc(((40 * 50vh) / 100) / 5);
	}
}
@media only screen and (min-width: 1366px) and (max-width: 1680px) {
	#slider-customize-img .slick-prev,
	#slider-customize-img .slick-next {
		width: 40px;
		height: 30px;
	}

	#slider-customize-img .slick-prev:before,
	#slider-customize-img .slick-next:before {
		font-size: 30px;
	}

	.Div span {
		font: bold 14px 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	}
}
@media only screen and (max-width: 1440px) and (min-height: 768px) {
	.Menues {
		padding: 0 0.75rem;
	}
	.MenuesChild {
		grid-auto-rows: auto;
	}
	/* .Cards svg,
	.Cards img {
		height: 65%;
		width: calc(((50 * 65vh) / 100) / 5);
	} */
	.Cards,
	.Cards a {
		justify-content: flex-start;
	}
	.Cards span {
		font-weight: 500;
	}
	.Footer a span {
		font-size: 14px;
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) and (min-height: 991px) {
	.Cards svg,
	.Cards img {
		height: 50%;
		width: calc(((50 * 50vh) / 100) / 5);
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) and (min-height: 769px) and (max-height: 990px) {
	.Cards svg,
	.Cards img {
		height: 50%;
		width: calc(((50 * 50vh) / 100) / 5);
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) and (min-height: 480px) and (max-height: 768px) {
	.Cards svg,
	.Cards img {
		height: 50%;
		width: calc(((50 * 50vh) / 100) / 5);
	}
}

@media only screen and (max-width: 1280px) {
	.Cards {
		padding: 0 0.5rem 0 0;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) and (min-height: 769px) {
	.Cards {
		padding: 0;
	}
	.Cards svg,
	.Cards img {
		height: 50%;
		width: calc(((50 * 50vh) / 100) / 5);
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) and (min-height: 550px) and (max-height: 768px) {
	.Cards {
		padding: 0;
	}
	.Cards svg,
	.Cards img {
		height: 50%;
		width: calc(((50 * 50vh) / 100) / 5);
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) and (min-height: 300px) and (max-height: 550px) {
	.Cards {
		padding: 0;
	}
	.Cards svg,
	.Cards img {
		height: 40%;
		width: calc(((40 * 40vh) / 100) / 5);
	}
}


@media only screen and (min-width: 1024px) and (max-width: 1365px) {
	/* .Container {
		height: calc(100vh - 128px);
	} */
	.MenuesChild {
		grid-column-gap: 6px;
	}
	#slider-customize-img .slick-prev,
	#slider-customize-img .slick-next {
		width: 40px;
		height: 30px;
	}
	#slider-customize-img .slick-prev:before,
	#slider-customize-img .slick-next:before {
		font-size: 30px;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1365px) and (min-height: 576px) and (max-height: 720px) {
	.Div span {
		font-size: 12px;
	}
	.Footer {
		padding: 0.5rem 0 0 0;
	}
	.Footer a img {
		height: 20px;
	}
}
@media only screen and (min-width: 1024px) and (max-width: 1365px) and (max-height: 576px) {
	.Cards,
	.Cards a {
		justify-content: flex-start;
	}
	.Div span {
		font-size: 14px;
	}
}

@media only screen and (max-width: 1224px) and (min-width: 768px) {
	.Div span {
		font: bold 14px 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	}
	.Cards svg {
		width: 25% !important;
		height: 20%;
	}
}

@media only screen and (max-width: 1024px) and (min-height: 380px) {
	.Cards svg,
	.Cards img {
		height: 40%;
		width: calc(((40 * 40vh) / 100) / 5);
	}
}

@media only screen and (min-width: 768px) and (max-width: 1224px) and (max-height: 678px) {
	.Container {
		padding: 0 0.75rem;
	}
	.Cards {
		padding: 0 0.25rem;
	}
	.Footer {
		padding: 0.25rem 0 0 0;
	}
	.Footer a img {
		height: 1rem;
	}
	.Div span {
		font-size: 10px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1224px) and (max-height: 590px) {
	.Container {
		/* height: calc(100vh - 82px); */
		padding: 0 0.75rem;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (max-height: 678px) {
	.Container {
		padding: 0 0.75rem;
	}
}
@media only screen and (max-width: 760px) {
	.ContainerTab {
		padding: 0 1rem;
	}
	.ContainerTab.section > div {
		margin-bottom: 1rem;
	}
	.Menues {
		padding: 0;
	}
	.MenuesChild {
		grid-template-columns: auto auto;
	}
	.Cards {
		margin: 1rem 0;
	}
	.Cards,
	.Cards a {
		height: auto;
	}
	.Cards svg,
	.Cards img {
		width: calc(((50 * 50vw) / 100) / 2);
	}
	.Cards img {
		/* height: 50%; */
		height: 10vh;
	}
	.Div span {
		font: bold 14px 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	}
	.Footer .Div a {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
	}
	.Footer > .Div > a > span {
		margin: 0 0.25rem 0 0;
	}
}
