.navbar {
	position: relative;
	display: flex;
	justify-content: center;
	background-color: #232a3a;
	-webkit-animation: fade-in-top-2 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in-top-2 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
}

.wrapper {
	width: 100%;
	/* height: 100%; */
	display: flex;
	flex-direction: row;
}
.wrapper1 {
	width: 88%;
}

.part {
	display: flex;
	width: calc(100% / 3);
	height: 100%;
	position: relative;
}

.a {
	width: 100%;
	padding: 0 2rem;
	display: flex;
	flex-direction: row;
}

.a span {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.b {
	width: 45%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: 1px solid #e8ecf5;
}

.bL {
	width: auto;
	height: 70%;
}

.b img {
	object-fit: contain;
	width: 100%;
	height: 100%;
	padding: 0 1rem;
}

.c {
	width: 55%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 1rem;
}

.aLink {
	display: flex;
	flex-direction: row;
	text-decoration: none;
	color: inherit;
	display: contents;
}
.aLink:hover {
	color: inherit;
}

.a_1 {
	width: 100%;
	padding: 0.75rem 2rem;
	display: flex;
	flex-direction: row;
}

.a_1 span {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.d {
	width: 30%;
	display: flex;
	justify-content: flex-end;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 36px;
}

.e {
	width: 10%;
	display: flex;
	justify-content: center;
	margin: auto;
}

.e img,
.e svg {
	width: 32px;
	height: 32px;
}

.f img,
.f svg {
	width: 23px;
	height: 23px;
	margin-left: 0.5rem;
}

.f {
	width: 60%;
	display: flex;
	flex-direction: column;
}

.SelectLang {
	background-color: unset;
	/* color: #e8ecf5; */
	border: 0;
	font-size: 16px;
}

.SelectLang:focus {
	border: 0;
}

.SelectLang option {
	color: #232a3a;
}
.SelectLang:focus-visible {
	outline: none;
}

.g {
	width: 50%;
	justify-content: center;
	display: flex;
	height: 100%;
	align-items: center;
	align-content: center;
}

.btn_logout {
	border-radius: 30px;
	cursor: pointer;
	background-color: unset;
	width: fit-content;
	padding: 1rem;
}

.top {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
}

.loc {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 240px;
}

@-webkit-keyframes fade-in-top {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in-top {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@-webkit-keyframes fade-in-top-2 {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in-top-2 {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@media only screen and (min-width: 1920px) {
	.SelectLang {
		font-size: 18px;
	}
}

@media only screen and (max-width: 1440px) {
	.navbar {
		height: 88px;
	}
	.d {
		font-size: 32px;
	}
}

@media only screen and (min-width: 1366px) and (max-width: 1650px) {
	.a {
		padding: 0;
	}

	.btn_logout {
		padding: 0.5rem 1rem;
	}

	.a_1 {
		padding: 0;
	}

	.d span {
		margin: auto;
	}

	.d {
		width: 25%;
		justify-content: center;
	}

	.f {
		width: 65%;
		justify-content: center;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1365px) and (min-height: 576px) and (max-height: 720px) {
	.c button {
		padding: 0.5rem;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1365px) and (max-height: 576px) {
	.c button {
		padding: 0.5rem;
	}
}

@media only screen and (max-width: 1280px) {
	.part:nth-child(1) {
		width: calc(100% / 2.75);
	}
	.part:nth-child(2) {
		width: calc(100% / 3.5);
	}
	.part:nth-child(3) {
		width: calc(100% / 2.75);
	}
	.navbar {
		height: 10vh;
	}
	.a {
		padding: 0 1rem 0 2rem;
	}
	.a_1 {
		padding: 0;
	}

	.btn_logout {
		width: fit-content;
	}

	.d {
		width: 25%;
		justify-content: left;
	}

	.d span {
		margin: auto;
	}

	.e {
		width: 20%;
	}

	.f {
		width: 55%;
		justify-content: center;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1224px) and (max-height: 678px) {
	.btn_logout {
		padding: 0.25rem;
		font-size: 12px;
	}
	.SelectLang {
		font-size: 12px;
	}
	.a {
		padding: 0.25rem 0.5rem !important;
	}
	/* .a_1 span {
		font-size: 16px;
	} */
	.c span {
		font-size: 10px;
	}
	.e img,
	.e svg {
		width: 28px;
		height: 28px;
	}
	.f {
		justify-content: space-evenly;
	}
}

@media only screen and (min-width: 868px) and (max-width: 1023px) {
	.navbar {
		height: 12vh;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.wrapper1 {
		width: 100%;
	}
	.a {
		padding: 0.75rem 1rem;
	}
	.bL {
		height: 100%;
	}
	.b img {
		padding: 0 1rem 0 0;
	}
}

@media only screen and (max-width: 769px) {
	.btn_logout {
		width: unset;
		padding: 0.5rem 1rem;
	}

	.d {
		width: 15%;
		justify-content: left;
	}

	.d span {
		margin: auto;
		font-size: 18px;
	}

	.e {
		width: 10%;
	}

	.f {
		width: 75%;
		justify-content: center;
	}

	.a_1 {
		padding: 0;
	}
}

@media only screen and (max-width: 760px) {
	.aLink {
		width: 100%;
		height: 100%;
		display: flex;
	}
	.d {
		width: auto;
		justify-content: left;
	}
	.e {
		width: 20%;
		margin: 0;
	}
	.f img {
		width: 14px;
		height: 14px;
	}
	.f > div {
		margin-top: 0 !important;
		align-items: center;
	}
}
