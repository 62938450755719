.MenuList {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin: auto;

	justify-content: center;
	flex-wrap: wrap;
}

.MenuList .btn {
	padding: 0.5rem 0.75rem;
	width: auto;
	margin-right: 0.5rem;
	margin-top: 0.25rem;
	display: flex;
	align-items: center;
	border: none;
}

.MenuList a {
	color: inherit;
	text-decoration: none;
}

.button .btn {
	margin: unset;
}

.MenuList span {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	/* font-size: 18px; */
	font-weight: bold;
	text-align: center;
	margin: 0 1rem;
}

.CardMini {
	width: calc(100% / 5);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.CardMini svg {
	width: 25% !important;
	height: 20%;
	margin: 1rem auto;
	color: #333333;
}

.CardMini span {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	margin-top: 1rem;
}

@media only screen and (max-width: 1920px) {
	.MenuList {
		height: 100%;
	}
}

@media only screen and (max-width: 1680px) {
	.MenuList .btn {
		padding: 0.25rem 0.5rem;
	}
}

@media only screen and (min-width: 1366px) and (max-width: 1660px) {
	.MenuList {
		height: unset;
	}

	.MenuList span {
		margin: unset;
	}
}


@media only screen and (min-width: 1024px) and (max-width: 1365px) and (min-height: 576px) and (max-height: 720px) {
	.MenuList .btn {
		padding: 0.25rem 0.5rem;
	}
	.MenuList span {
		font-weight: 500;
	}
}
@media only screen and (min-width: 1024px) and (max-width: 1365px) and (max-height: 576px) {
	.MenuList .btn {
		padding: 0.25rem 0.5rem;
	}
}

@media only screen and (max-width: 1024px) {
	.MenuList .btn {
		margin-top: 0.25rem;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1224px) {
	.MenuList {
		justify-content: flex-start;
		height: unset;
	}

	.MenuList button {
		/* padding: 0.375rem 1.5rem; */
		padding: 0.375rem 0.5rem;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1224px) and (max-height: 678px) {
	.MenuList .btn {
		/* margin-top: 0.25rem; */
		margin-right: 0.25rem;
		padding: 0.25rem;
	}
}
