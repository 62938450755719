.Tabs6 {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.TopSet {
	/* height: 20% !important; */
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.TopSet h5 {
	margin: 0;
}

.__slider {
	width: 100%;
	height: 100%;
}
.TopSet h5 {
	margin: 0;
}

.__slider {
	width: 100%;
	height: 100%;
}

.CardImgs {
	width: 100%;
	height: 100%;
}

.aCardImgs {
	width: 100% !important;
	height: 100%;
	display: block;
    position: relative;
    z-index: 1000;
}

.CardImgs {
	width: 100%;
	height: 100%;
}
.BtmSet {
	/* height: 80% !important; */
	height: 240px;
	width: 100% !important;
	background-color: gray !important;
}

.aCardImgs {
	width: 100% !important;
	height: 100%;
	display: block;
	position: relative;
	z-index: 1000;
}

.CardImgs img,
.CardImgs img,
.aCardImgs img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.TopSet h1 {
	font-size: 16px;
	font-weight: bold;
	width: 80%;
	height: 100%;
}
.BtmSet {
	/* height: 80% !important; */
	height: 240px;
	width: 100% !important;
	background-color: gray !important;
}

.Icons {
	width: 20%;
	height: auto;
	display: flex;
}

.Tabs6 div:first-child > div > svg {
	margin: auto;
}

.dummyImage {
	object-fit: contain !important;
}

@media only screen and (min-width: 768px) and (max-width: 1224px) and (max-height: 678px) {
	.Tabs6 h5 {
		font-size: 12px;
	}
	.Tabs6 div:first-child {
		margin-bottom: 0.25rem;
	}
	.CardImgs img,
	.CardImgs img,
	.aCardImgs img {
		height: 100%;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (max-height: 6787px) {
	.TopSet {
		padding: 0.5rem 0;
	}
	.BtmSet {
		height: 100%;
	}
}

@media only screen and (max-width: 760px) {
	.TopSet {
		padding: 0.5rem 0;
	}
	.TopSet h5 {
		font-size: 14px;
	}
	.TopSet svg {
		width: 12px;
		height: 12px;
	}
}
