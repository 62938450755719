.TabS5 {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-right: 2rem;
}

.TabS5 div:first-child {
	height: 100%;
	width: 100%;
}

.TabS5 div:first-child > img,
.TabS5 div:first-child > a > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
