.logo {
	width: auto;
	height: auto;
	position: absolute;
	top: 1rem;
	left: 1rem;
	z-index: 1;
}

.logo span {
	font: normal 30px/37px Roboto, arial, sans-serif;
	color: var(--secondary-color);
}

.logo img {
	width: auto;
    height: 40px;
}

.login {
	width: 100%;
	min-height: 100vh;
	height: 100%;
	background: rgb(255, 255, 255);
	display: flex;
	justify-content: center;
	align-items: center;
}

.contentLogin {
	display: flex;
	flex-direction: column;
	width: 35%;
	height: auto;
}

.contentLogin h1 {
	margin-bottom: 1.5rem;
}

.contentLogin span {
	margin-bottom: 1rem;
}
.contentLogin input {
	margin: 1rem 0;
	padding: 0.75rem;
}

.link {
	margin: 1rem auto;
	text-align: center;
	text-decoration: none;
}

.link:hover {
	text-decoration: none;
}

.signIn {
	width: 100%;
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	background: #17438b;
	border-radius: 0.5rem;
	margin-bottom: 1rem;
	cursor: pointer;
	text-transform: uppercase;
	border-width: 0;
}

.bimNotAllowed {
	opacity: 0.4;
	cursor: not-allowed !important;
}

.register {
	background: #ffffff;
	color: #000000;
	border: 2px solid #17438b;
	margin-top: 1rem;
	text-decoration: none;
}

@media only screen and (min-width: 768px) and (max-width: 1240px) {
	.login {
		width: 80vw;
		margin: 0 auto;
	}
	.contentLogin {
		width: 100%;
	}
}

@media only screen and (max-width: 767px) {
	.login {
		width: 80vw;
		margin: 0 auto;
	}
	.contentLogin {
		width: 100%;
	}
}
