.Board5 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}
.Board5Basic {
  width: 100%;
}
.Board5Standard {
  width: 100%;
}
.Board5Basic {
	width: 100%;
}
.Board5Standard {
	width: 100%;
}

.Top5 {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  height: 15%;
  align-items: center;
  /* margin-bottom: .5rem; */
  width: 100%;
  justify-content: space-between;
}

.Top5 h5 {
  margin-bottom: unset;
}

.Top5 .Svg {
  width: 10%;
  display: flex;
  justify-content: center;
}

.Svg svg {
  margin: auto;
}

.Top5 h1 {
  font-size: 32px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  width: 90%;
  margin: auto;
}
.Btm5 {
  width: 100%;
  height: 100%;
}

.Btm5 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 1366px) and (max-width: 1648px) {
  .Top5 h1 {
    font-size: 16px;
  }
}

